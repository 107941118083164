<template>
  <b-card-actions
      ref="card-actions"
      class="actions-card"
      @refresh="refreshStop('card-actions')"
  >
    <b-row class="mt-50">
      <div class="card-header-row" :style="{backgroundColor: $store.getters.COLORS_GET.cardTitle}">
        <b-col cols="12">
          <h4 class="text-white d-inline-block card-title">Alış Faturaları</h4>
          <b-button
              variant="flat-primary"
              class="text-white float-right bg-primary bg-lighten-2 card-right-button"
              to="/yeniAlis"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :style="{backgroundColor:$store.getters.COLORS_GET.cardTitleButton+'!important',border:'none'}"
          >
            Fatura OLuştur
          </b-button>
        </b-col>
      </div>
    </b-row>
    <b-tabs class="mt-4" :style="{marginTop : width < 333 ? '6rem!important':'4rem'}">
      <b-tab title="Açık Faturalar">
        <b-row>
          <b-col lg="6" md="12" sm="12" cols="12" clas="d-flex justify-content-start">
            <b-form-group class="mr-1 mb-md-0 unPrint" size="sm">
              <b-input-group
                  size="sm"
                  prepend="Gösterilecek kayıt sayısı"
                  class="unPrint per-page"
              >
                <b-form-select
                    v-model="firstPerPage"
                    :options="firstPageOptions"
                    size="sm"
                    @change="firstPerPageChange($event)"
                >
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="12" sm="12" cols="12" class="d-flex justify-content-end">
            <b-input-group size="sm" class="search-bar" :style="{width: width < 1300 ? '60%!important':''}">
              <b-input-group-prepend>
                <b-button
                    variant="outline-primary"
                    size="sm"
                    disabled
                    class="search-input mb-0"
                >
                  Ara
                </b-button>
              </b-input-group-prepend>
              <b-form-input id="filterInput" v-model="firstFilter" type="search"/>
            </b-input-group>
          </b-col>
        </b-row>
        <hr/>
        <b-table
            striped
            small
            hover
            responsive
            class="position-relative cari-group-table mt-1"
            :items="acikFatura"
            :fields="fields"
            :per-page="firstPerPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter-included-fields="filterOn"
            @filtered="FirstOnFiltered"
            @row-clicked="goFatura"
        >
          <template #cell(kayitID)="data">
            <b-badge variant="primary" class="align-top">
              #{{ data.item.kayitID }}
            </b-badge>
          </template>
          <template #cell(faturaUnvan)="data">
            <strong class="text-dark fatura-notu" v-if="data.item.faturaNotu"
                    v-b-tooltip.hover.top="data.item.faturaNotu"
            >{{
                data.item.faturaNotu.slice(0, 70)
              }}{{ data.item.faturaNotu.length > 60 ? '...' : '' }}</strong>
            <p class="mb-0" v-if="data.item.faturaUnvan">
              {{ data.item.faturaUnvan.slice(0, 70) }}
              {{ data.item.faturaUnvan > 60 ? '...' : '' }}
            </p>
            <p class="mb-0" v-else>
              {{
                (data.item.adi, data.item.soyAdi).slice(0, 70)
              }}{{ data.item.adi.length + data.item.soyAdi.length > 60 ? '...' : '' }}
            </p>
          </template>
          <template #cell(kayitTarih)="data">
            <b-badge
                id="bakiye-badge"
                variant="light-primary"
                size="sm"
            >
              {{ data.item.kayitTarih.slice(0, 16) }}
            </b-badge>
          </template>
          <template #cell(toplamTutar)="data">
            <strong v-if="data.item.toplamTutar" class="text-nowrap">
              <small class="d-block text-right font-weight-bolder"> Bakiye: {{
                  Number(data.item.finans.bakiye)
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                }}
                <font-awesome-icon icon="fa-solid fa-turkish-lira-sign"/>
              </small>
              <small class="d-block text-right"> Yapılan Tahsilatlar: {{ data.item.finans.toplamTahsilat }}
                <font-awesome-icon icon="fa-solid fa-turkish-lira-sign"/>
              </small>
              <p class="bg-transparent text-right m-0">
                Toplam Tutar: {{
                  Number(data.item.toplamTutar)
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                }}
                <font-awesome-icon icon="fa-solid fa-turkish-lira-sign"/>
              </p>
            </strong>
          </template>
        </b-table>
        <hr/>
        <b-row class="d-flex align-items-center mt-1">
          <b-col cols="6">
            <p class="text-muted">Toplam {{ firstTotalRows }} Kayıt bulundu</p>
          </b-col>
          <b-col cols="6">
            <b-pagination
                v-model="firstCurrentPage"
                @change="firstPageChange($event)"
                :total-rows="firstTotalRows"
                :per-page="firstPerPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0 float-right"
            />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Kapalı Faturalar" @click="kapaliFaturaGet">
        <b-row>
          <b-col lg="6" md="12" sm="12" cols="12" clas="d-flex justify-content-start">
            <b-form-group class="mr-1 mb-md-0 unPrint" size="sm" style="width: 30%" :style="{width : perPageWidth}">
              <b-input-group
                  size="sm"
                  prepend="Gösterilecek kayıt sayısı"
                  class="unPrint"
              >
                <b-form-select
                    v-model="secondPerPage"
                    :options="secondPageOptions"
                    size="sm"
                    @change="secondPerPageChange($event)"
                >
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="12" sm="12" cols="12" class="d-flex justify-content-end">
            <b-input-group size="sm" class="search-bar"
                           :style="{width : width < 400 ? '100%!important' : width ? '100%!important': width < 600 ? '50%!important':'40%!important'}"
            >
              <b-input-group-prepend>
                <b-button
                    variant="outline-primary"
                    size="sm"
                    disabled
                    class="search-input mb-0"
                >
                  Ara
                </b-button>
              </b-input-group-prepend>
              <b-form-input id="filterInput" v-model="secondFilter" type="search"/>
            </b-input-group>
          </b-col>
        </b-row>
        <hr/>
        <b-table
            striped
            small
            hover
            responsive
            class="position-relative cari-group-table mt-1"
            :items="kapaliFatura"
            :fields="fields"
            :per-page="secondPerPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter-included-fields="filterOn"
            @filtered="SecondOnFiltered"
            @row-clicked="goFatura"
        >
          <template #cell(kayitID)="data">
            <b-badge variant="primary" class="align-top">
              #{{ data.item.kayitID }}
            </b-badge>
          </template>
          <template #cell(faturaUnvan)="data">
            <b-row>
              <b-col cols="1">
                <b-badge
                    id="bakiye-badge"
                    :variant="data.item.faturaTuru == 1 ? 'light-success': 'light-primary'"
                    size="sm"
                    :class="{'fatura-turu': data.item.faturaNotu }"
                >
                  {{ data.item.faturaTuru == 1 ? 'Satış' : 'Alış' }}
                </b-badge>
              </b-col>
              <b-col cols="11" class="fatura-unvan-col-2">
                <strong class="text-dark fatura-notu" v-if="data.item.faturaNotu"
                        v-b-tooltip.hover.top="data.item.faturaNotu"
                >{{
                    data.item.faturaNotu.slice(0, 70)
                  }}{{ data.item.faturaNotu.length > 60 ? '...' : '' }}</strong>

                <p class="mb-0" v-if="data.item.faturaUnvan">
                  {{ data.item.faturaUnvan }}
                </p>
                <p class="mb-0" v-else>
                  {{ data.item.adi }}
                  {{ data.item.soyAdi }}
                </p>
              </b-col>
            </b-row>
          </template>
          <template #cell(kayitTarih)="data">
            <b-badge
                id="bakiye-badge"
                variant="light-primary"
                size="sm"
            >
              {{ data.item.kayitTarih.slice(0, 16) }}
            </b-badge>
          </template>
          <template #cell(toplamTutar)="data">
            <strong v-if="data.item.toplamTutar" class="text-nowrap">
              <small class="d-block text-right font-weight-bolder"> Bakiye: {{
                  Number(data.item.finans.bakiye)
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                }}
                <font-awesome-icon icon="fa-solid fa-turkish-lira-sign"/>
              </small>
              <small class="d-block text-right"> Yapılan Tahsilatlar: {{ data.item.finans.toplamTahsilat }}
                <font-awesome-icon icon="fa-solid fa-turkish-lira-sign"/>
              </small>
              <p class="bg-transparent text-right m-0">
                Toplam Tutar: {{
                  Number(data.item.toplamTutar)
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                }}
                <font-awesome-icon icon="fa-solid fa-turkish-lira-sign"/>
              </p>
            </strong>
          </template>
        </b-table>
        <hr/>
        <b-row class="d-flex align-items-center mt-1">
          <b-col cols="6">
            <p class="text-muted">Toplam {{ secondTotalRows }} Kayıt bulundu</p>
          </b-col>
          <b-col cols="6">
            <b-pagination
                v-model="secondCurrentPage"
                @change="secondPageChange($event)"
                :total-rows="secondTotalRows"
                :per-page="secondPerPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0 float-right"
            />
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </b-card-actions>
</template>

<script>
import {
  BTab,
  BFormSelect,
  BTabs,
  BSidebar,
  BModal,
  BInputGroupAppend,
  BPagination,
  VBTooltip,
  BBadge,
  BTable,
  BRow, BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BInputGroup,
  BInputGroupPrepend,
  BForm,
  BButton,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import { ModelListSelect } from 'vue-search-select/dist/VueSearchSelect.common'
import router from '@/router'
import store from '@/store'
import { useWindowSize } from '@vueuse/core'

export default {
  setup() {
    const {
      width,
    } = useWindowSize()
    return {
      width,
    }
  },
  components: {
    BTab,
    BFormSelect,
    BTabs,
    BSidebar,
    BPagination,
    BButton,
    BForm,
    BInputGroup,
    BFormCheckbox,
    BFormInput,
    BFormGroup,
    BTable,
    BBadge,
    BRow,
    BCol,
    BInputGroupAppend,
    BInputGroupPrepend,
    BModal,
    BCardActions,
    ModelListSelect,
  },
  data() {
    return {
      fields: [
        {
          key: 'kayitID',
          label: '',
          sortable: true,
          thStyle: {
            width: '1%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '1%' },
        },
        {
          key: 'faturaUnvan',
          label: 'Fatura Bilgisi',
          sortable: true,
          thStyle: {
            width: '60%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '60%' },
        },
        {
          key: 'kayitTarih',
          label: 'Düzenlenme Tarihi',
          sortable: true,
          thStyle: {
            width: '15%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '15%' },
        },
        {
          key: 'toplamTutar',
          label: 'Bakiye',
          sortable: true,
          thStyle: {
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
            width: '20%',
            textAlign: 'end',
          },
          tdClass: ['d-flex', 'justify-content-end'],
          tdStyle: { width: '20%' },
        },
      ],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filterOn: [],
      firstFilter: null,
      firstPerPage: 20,
      firstPageOptions: [5, 10, 20, 50, 100, 200, 500],
      firstToplamKayit: 1,
      firstTotalRows: 1,
      firstCurrentPage: 1,
      secondFilter: null,
      secondPerPage: 20,
      secondPageOptions: [5, 10, 20, 50, 100, 200, 500],
      secondToplamKayit: 1,
      secondTotalRows: 1,
      secondCurrentPage: 1,
    }
  },
  computed: {
    perPageWidth() {
      return this.width < 400 ? '100%' : this.width < 540 ? '70%' : this.width < 700 ? '50%' : this.width < 992 ? '40%' : this.width < 1100 ? '70%' : this.width < 1400 ? '50%' : this.width < 1730 ? '40%' : '30%'
    },
    acikFatura() {
      return store.getters.FATURA_LIST_GET.acikFatura
    },
    kapaliFatura() {
      return store.getters.FATURA_LIST_GET.kapaliFatura
    },
  },
  watch: {
    secondFilter(newVal) {
      this.$refs['card-actions'].showLoading = true
      this.$store
          .dispatch('faturaListesi', {
            uzunluk: this.secondPerPage,
            baslangic: 0,
            searchKey: newVal,
            stateTur: 'kapali',
            faturaTuru: 2,
          })
          .then(res => {
            this.$refs['card-actions'].showLoading = false
            this.secondTotalRows = res.toplamKayit
            this.secondToplamKayit = res.data?.length
          })
    },
    firstFilter(newVal) {
      this.$refs['card-actions'].showLoading = true
      this.$store
          .dispatch('faturaListesi', {
            uzunluk: this.firstPerPage,
            baslangic: 0,
            searchKey: newVal,
            stateTur: 'acik',
            faturaTuru: 2,
          })
          .then(res => {
            this.$refs['card-actions'].showLoading = false
            this.firstTotalRows = res.toplamKayit
            this.firstToplamKayit = res.data?.length
          })
    },
  },
  created() {
    store.dispatch('faturaListesi', {
      uzunluk: this.firstPerPage,
      baslangic: 0,
      searcKey: this.firstFilter,
      stateTur: 'acik',
      faturaTuru: 2,
    })
        .then(res => {
          this.firstTotalRows = res.toplamKayit
          this.firstToplamKayit = res.data.length
        })
  },
  methods: {
    secondPageChange(newVal) {
      const veri = newVal * this.secondPerPage - this.secondPerPage
      this.$refs['card-actions'].showLoading = true
      this.$store
          .dispatch('faturaListesi', {
            uzunluk: this.secondPerPage,
            baslangic: veri,
            searchKey: this.secondFilter,
            stateTur: 'kapali',
            faturaTuru: 2,
          })
          .then(res => {
            this.$refs['card-actions'].showLoading = false
            this.secondToplamKayit = res.data.length
            this.secondTotalRows = res.toplamKayit
          })
    },
    secondPerPageChange(newVal) {
      const veri = this.secondCurrentPage * newVal - newVal
      this.$refs['card-actions'].showLoading = true
      this.$store
          .dispatch('faturaListesi', {
            uzunluk: newVal,
            baslangic: veri,
            searchKey: this.secondFilter,
            stateTur: 'kapali',
            faturaTuru: 2,
          })
          .then(res => {
            this.$refs['card-actions'].showLoading = false
            this.secondToplamKayit = res.data.length
            this.secondTotalRows = res.toplamKayit
          })
    },
    kapaliFaturaGet() {
      store.dispatch('faturaListesi', {
        uzunluk: this.secondPerPage,
        searcKey: this.secondFilter,
        baslangic: 0,
        stateTur: 'kapali',
        faturaTuru: 2,
      })
          .then(res => {
            this.secondTotalRows = res.toplamKayit
            this.secondToplamKayit = res.data.length
          })
    },
    goFatura(item) {
      router.push({
        name: 'faturaDetay',
        params: { id: item.kayitID },
      })
    },
    firstPageChange(newVal) {
      const veri = newVal * this.firstPerPage - this.firstPerPage
      this.$refs['card-actions'].showLoading = true
      this.$store
          .dispatch('faturaListesi', {
            uzunluk: this.firstPerPage,
            baslangic: veri,
            searchKey: this.firstFilter,
            stateTur: 'acik',
            faturaTuru: 2,
          })
          .then(res => {
            this.$refs['card-actions'].showLoading = false
            this.firstToplamKayit = res.data.length
            this.firstTotalRows = res.toplamKayit
          })
    },
    firstPerPageChange(newVal) {
      const veri = this.firstCurrentPage * newVal - newVal
      this.$refs['card-actions'].showLoading = true
      this.$store
          .dispatch('faturaListesi', {
            uzunluk: newVal,
            baslangic: veri,
            searchKey: this.firstFilter,
            stateTur: 'acik',
            faturaTuru: 2,
          })
          .then(res => {
            this.$refs['card-actions'].showLoading = false
            this.firstToplamKayit = res.data.length
            this.firstTotalRows = res.toplamKayit
          })
    },
    FirstOnFiltered(filteredItems) {
      this.firstTotalRows = filteredItems.length
      this.firstCurrentPage = 1
    },
    SecondOnFiltered(filteredItems) {
      this.secondTotalRows = filteredItems.length
      this.secondCurrentPage = 1
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName || 'fatura-listesi'].showLoading = false
      }, 1000)
    },
  },
  beforeDestroy() {
    store.commit({
      type: 'FATURA_LIST_ACTION',
      stateTur: 'remove',
    })
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
}
</script>
<style scoped>
.card-right-button {
  transform: translateY(7%) !important;
}

.card-title {
  transform: translateY(45%) !important;
}

.search-bar {
  width: 30% !important;
}

.search-input {
  border-color: #c3baba !important;
}

.fatura-turu {
  transform: translateY(50%) !important;
}

.col-8, .col-4 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

@media screen and (max-width: 1140px) {
  .fatura-unvan-col-2 {
    transform: translateX(2%);
  }
}

.lira-color {
  color: #e83e8c !important;
}

@media screen and (max-width: 3000px) {
  .per-page {
    width: 30% !important;
  }
}

@media screen and (max-width: 2000px) {
  .per-page {
    width: 35% !important;
  }
}

@media screen and (max-width: 1800px) {
  .per-page {
    width: 40% !important;
  }
}

@media screen and (max-width: 1600px) {
  .per-page {
    width: 50% !important;
  }
}

@media screen and (max-width: 1200px) {
  .per-page {
    width: 50% !important;
  }
}

@media screen and (max-width: 1100px) {
  .per-page {
    width: 55% !important;
  }
}

@media screen and (max-width: 1000px) {
  .per-page {
    width: 65% !important;
  }
}

@media screen and (max-width: 900px) {
  .per-page {
    width: 75% !important;
  }
}

@media screen and (max-width: 800px) {
  .per-page {
    width: 90% !important;
  }
}
</style>
